import { states, URL_REGEX_NO_CAP_GRP as URL_REGEX } from 'services/constants'
import { log } from './log'

const ordinals = new Map([
  [0, 'zero'],
  [1, 'one'],
  [2, 'two'],
  [3, 'three'],
  [4, 'four'],
  [5, 'five'],
  [6, 'six'],
  [7, 'seven'],
  [8, 'eight'],
  [9, 'nine'],
  [10, 'ten'],
  [11, 'eleven'],
  [12, 'twelve'],
  [13, 'thirteen'],
  [14, 'fourteen'],
  [15, 'fifteen'],
  [16, 'sixteen'],
  [17, 'seventeen'],
  [18, 'eighteen'],
  [19, 'nineteen'],
  [20, 'twenty'],
])

const vowels = ['a', 'e', 'i', 'o', 'u']

export const numberToOrdinal = _num => {
  const num = Number(_num)
  if (isNaN(num)) {
    throw new Error('called numberToOrdinal(x) with NaN x')
  }
  if (num < 0) {
    throw new Error('called numberToOrdinal(x) with x < 0')
  }
  if (!ordinals.has(num)) {
    return 'more than twenty'
  }
  return ordinals.get(num)
}

/**
 * @param array Array of strings to join
 * @param firstN If provided then this method takes only first N items from the array, joins them,
 *  and then it appends count of remaining items to the resulting string
 * @param separator Separator used to join items
 * @returns {string}
 *
 * @example
 *
 * join(['one', 'two', 'three', 'four']) === 'one, two, three, four'
 * join(['one', 'two', 'three', 'four'], 2) === 'one, two, +2'
 */
export const join = (array, firstN = null, separator = ', ') => {
  firstN = firstN ?? array.length
  const items = array.slice(0, firstN)
  const remainingItemsCount = array.length > firstN ? `+${array.length - firstN}` : ''
  return [...items, remainingItemsCount].filter(Boolean).join(separator)
}

export const stateAbbreviationToName = abbr => {
  if (!abbr) {
    return undefined
  }
  abbr = abbr.toLowerCase()
  return states.find(val => val.abbr.toLowerCase() === abbr)?.name
}

export const parseUrlsFromString = inStr => {
  try {
    if (typeof inStr !== 'string') {
      throw new Error(
        `parseUrlsFromString takes a string parameter; called with ${typeof inStr} instead`
      )
    }

    const sliced = inStr.split(URL_REGEX)

    if (sliced.length < 2) {
      return sliced
    }

    const out = []
    for (const substring of sliced) {
      try {
        const substringUrl = new URL(substring)
        out.push(substringUrl)
      } catch (_couldNotConstructUrl) {
        // The regex will match e.g. 'www.google.com', but the URL constructor will throw with that input.
        // If we failed once, it may be worth adding on a protocol and trying that again.
        try {
          const withProtocol = new URL(`http://${substring}`)
          out.push(withProtocol)
        } catch (_stillCouldNotConstruct) {
          // Still didn't work, so push the original substring.
          out.push(substring)
        }
      }
    }

    return out
  } catch (err) {
    console.error(err)
    return [inStr]
  }
}

export const withFullStop = inStr => (/\.$/.test(inStr) ? inStr : `${inStr}.`)

export const pluralizeTime = (count, period) => {
  const validPeriods = ['hours', 'days', 'weeks', 'months', 'years']
  count = Number(count)

  if (!validPeriods.includes(period.toLowerCase()) || typeof count !== 'number') {
    log('Pluralize error: Not a valid period or count', 'error', { count, period })
    return
  }

  if (count === 0) {
    return 'Immediately'
  } else if (count === 1) {
    return `${count} ${period.slice(0, -1)}`
  } else {
    return `${count} ${period}`
  }
}

export const prependArticle = (word = '') => {
  const firstLetter = word?.[0]

  if (!word || !firstLetter) return ''

  if (vowels.includes(firstLetter.toLowerCase())) return `an ${word}`

  return `a ${word}`
}

export function capitalize(str) {
  if (!str) {
    return ''
  }

  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function capitalizeWithExceptions(str) {
  if (!str) return

  const exceptions = ['of', 'the', 'for', 'a', 'or', 'with', 'on', 'to', 'and']

  return str
    .split(' ')
    .map((word, i) => {
      if (!exceptions.includes(word) && i !== 0) {
        word = capitalize(word)
      }

      return word
    })
    .join(' ')
}

export function ellipsized(text, maxLength) {
  if (text && text.length >= maxLength) {
    return text.substr(0, maxLength) + '...'
  }
  return text
}

export function getInitials(name) {
  const names = name.trim().split(' ')
  return names.reduce((initials, curr, i) => {
    if (i === 0 || i === names.length - 1) {
      const char = curr.charAt(0).toUpperCase()

      initials += char
    }
    return initials
  }, '')
}

export function fullTitle(provider) {
  const fullName = `${provider.first_name} ${provider.last_name}${
    provider.suffix ? ' ' + provider.suffix : ''
  }`
  return `${fullName}${
    provider.professional_title_short ? ', ' + provider.professional_title_short : ''
  }`
}
