import React, { useState } from 'react'
import Carousel from 'nuka-carousel'
import Img from 'components/img'
import useWindowSize from 'hooks/use_window_size'
import { Container } from 'components/grid'
import styles from './press.scss'

const PressSection = ({ slides }) => {
  slides = slides.filter(slide => slide?.logo?.src)
  const [slideIndex, setSlideIndex] = useState(0)
  const currentSlide = slides[slideIndex]
  let { width } = useWindowSize()

  return (
    <section className="press">
      <Container>
        <Carousel
          slideIndex={slideIndex}
          afterSlide={setSlideIndex}
          className="press-carousel"
          speed={750}
          autoplayInterval={6000}
          slidesToShow={width > 767 ? 5 : 3}
          dragging={false}
          cellAlign="center"
          transitionMode="scroll"
          withoutControls
          autoplay
          wrapAround>
          {slides.map((slide, index) => (
            <div key={index} onClick={() => setSlideIndex(index)} className="slide-wrapper">
              <Img
                publicId={slide.logo.src}
                alt={slide.name + ' logo'}
                width="250"
                quality="auto:good"
              />
            </div>
          ))}
        </Carousel>
        <div className="border-spacer" />
        <a href={currentSlide?.url} target="_blank" rel="noopener noreferrer">
          <figure className="press-quote">
            <blockquote cite={currentSlide?.url}>{currentSlide?.pullQuote}</blockquote>
            <figcaption className="overline_V3">- {currentSlide?.name}</figcaption>
          </figure>
        </a>
      </Container>
      <style jsx>{styles}</style>
    </section>
  )
}

export default PressSection
