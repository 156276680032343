import React from 'react'
import { toast } from 'react-toastify'
import { BEURO__Icon, BEURO__P } from 'styleguide/packages/components/index.js'

toast.configure({
  autoClose: false,
  draggable: false,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_LEFT,
  className: `custom-toastify`,
  bodyClassName: 'custom-toastify-body',
  closeButton: false,
  closeOnClick: false,
})

const Toast = props => {
  let { closeToast, message, toastType, iconName, viewBox = '0 0 32 32' } = props

  if (typeof message !== 'string') {
    console.error('Error message is not a string!', { message })
    message = 'Something went wrong!'
  }

  return (
    <div className="custom-toastify-container">
      <div className={`toastify-icon-container ${toastType}`}>
        <BEURO__Icon name={iconName} width="auto" height="40" viewBox={viewBox} />
      </div>

      <BEURO__P.BodySmall>{message}</BEURO__P.BodySmall>

      <span onClick={closeToast}>
        <BEURO__Icon className="close-icon" width="16" name="close" onClick={closeToast} />
      </span>
    </div>
  )
}

Toast.Success = props => (
  <Toast {...props} toastType={`--success`} iconName="checkmark" viewBox="0 0 24 26" />
)

Toast.SuccessGold = props => (
  <Toast {...props} toastType={`--success --gold`} iconName="checkmark" viewBox="0 0 24 26" />
)

Toast.Email = props => (
  <Toast {...props} toastType={`--success`} iconName="email" viewBox="-4 3 32 22" />
)

Toast.Text = props => (
  <Toast {...props} toastType={`--success`} iconName="text-message" viewBox="0 6 32 20" />
)

Toast.Error = props => <Toast {...props} toastType={`--error`} iconName="close" />

export default Toast
