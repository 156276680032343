import React, { useEffect } from 'react'
import classnames from 'classnames'
import { GOOGLE_ADSENSE_ID } from 'services/constants'
import { inDevelopment } from 'services/env'
import styles from './index.scss'

const AdSenseSlot = props => {
  const {
    slot,
    client = GOOGLE_ADSENSE_ID,
    format = 'auto',
    responsive = 'true',
    className = '',
    style = null,
    ...rest
  } = props

  useEffect(() => {
    try {
      window?.adsbygoogle?.push({})
    } catch (err) {
      console.error(err)
    }
  }, [client, slot])

  return (
    <div
      style={style}
      className={classnames(
        'adsense-ad-slot',
        inDevelopment ? 'adsense-ad-slot--debug' : '',
        className
      )}>
      {inDevelopment && <div className="ad-label">ad {slot}</div>}

      <ins
        {...rest}
        className="adsbygoogle"
        data-ad-client={`ca-pub-${client}`}
        data-ad-slot={slot}
        data-ad-format={format}
        data-full-width-responsive={responsive}
      />

      <style jsx>{styles}</style>
    </div>
  )
}

export { AdSenseSlot }
