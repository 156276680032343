import React from 'react'
import classnames from 'classnames'
import { Chiclet, BEURO__Icon } from 'styleguide/packages/components'

import styles from './index.scss'

const TryOn3DStaticBadge = props => {
  const { className } = props

  return (
    <>
      <Chiclet className={classnames('try-on-static-badge', className)}>
        <BEURO__Icon
          name="try-on-face"
          className="try-on-face-icon"
          viewBox="0 1 16 16"
          height="1rem"
          width="1rem"
        />
        3d try-on
      </Chiclet>
      <style jsx>{styles}</style>
    </>
  )
}

export default TryOn3DStaticBadge
