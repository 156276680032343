import dayjs from 'dayjs'
import { MAX_USER_CARDS } from 'services/constants'
import { round } from './abstract'

export const isExpiredCard = card => {
  const { expiration_month, expiration_year } = card

  // midnight after the last day of month
  const expiry = dayjs()
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .month(+expiration_month) // months are zero-indexed
    .year(+expiration_year)

  const now = dayjs()
  return expiry.isBefore(now)
}

export const formatCardExpiration = card => {
  const { expiration_month, expiration_year } = card
  const month = `${expiration_month}`.padStart(2, '0')
  const year = `${expiration_year}`.slice(-2)
  return `${month}/${year}`
}

export const hasActiveCard = user => {
  const cards = getUserCreditCards(user, true)
  return cards.length > 0
}

export const canAddCard = user => {
  const cards = getUserCreditCards(user)
  return cards.length < MAX_USER_CARDS
}

export const getUserCreditCards = (user, activeOnly = false) => {
  return (user?.payment_methods?.credit || [])
    .filter(card => !card.date_deleted)
    .sort((a, b) => {
      // if date is not set then the card was created recently and we don't have data from api yet
      const x = dayjs(a.date_created || undefined)
      const y = dayjs(b.date_created || undefined)
      return x.isAfter(y) ? +1 : x.isBefore(y) ? -1 : 0
    })
    .filter(card => !activeOnly || !isExpiredCard(card))
}

export const getConsultationFee = (provider = {}) => {
  const { consultation_fee = 0 } = provider
  return round(consultation_fee, 2, true)
}

export const getVoucherValue = (provider, procedureId) => {
  const { procedures } = provider
  const procedure = procedures.find(p => p.id === procedureId)

  const bookingFee = Number(provider?.consultation_fee)
  const voucherFee = Number(procedure?.voucher_value)
  const voucherValue = bookingFee + voucherFee

  return voucherValue ? round(voucherValue, 2, true) : null
}

export const SUBSCRIPTION_SOURCES = {
  VOUCHERS: 'vouchers',
  AEDITOR: 'AEDITOR',
  PROVIDER_B_A: 'provider_before_and_afters',
  PROCEDURE_B_A: 'procedure_before_and_afters',
  AEDITION_B_A: 'aedition_before_and_afters',
  B_A_PROVIDER_PAGE: 'before_and_afters_provider_page',
  B_A_PROCEDURE_PAGE: 'before_and_afters_procedure_page',
  BOOKMARKS: 'bookmark',
}

export const BEFORE_AND_AFTERS_SUBSCRIPTION_SOURCES = [
  SUBSCRIPTION_SOURCES.PROVIDER_B_A,
  SUBSCRIPTION_SOURCES.PROCEDURE_B_A,
  SUBSCRIPTION_SOURCES.AEDITION_B_A,
  SUBSCRIPTION_SOURCES.B_A_PROVIDER_PAGE,
  SUBSCRIPTION_SOURCES.B_A_PROCEDURE_PAGE,
]

export const buildPricingInfo = (plans = [], source = '') => {
  if (!plans || !plans.length || !source) return

  const products = plans.reduce((prods, plan) => {
    return {
      ...prods,
      [plan.apple_product_id]: {
        currency: plan.currency.toUpperCase(),
        cost: parseFloat(plan.amount),
      },
    }
  }, {})

  const subscription_sources = Object.values(SUBSCRIPTION_SOURCES).reduce((acc, src) => {
    const isPurchasedSource = src === source
    return {
      ...acc,
      [src]: { purchased: isPurchasedSource ? 1 : 0 },
    }
  }, {})

  return { subscription_sources, products }
}
