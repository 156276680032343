import { Image } from 'cloudinary-react'
import { getCloudinaryPublicId } from 'services/util/cloudinary'
import { useState } from 'react'
import styles from './img.scss'
import { CLOUDINARY, EMPTY_IMAGE } from 'services/constants'
import classnames from 'classnames'
import useCloudinaryImgOptions from './use-cloudinary-img-options'

export default function CloudinaryImg(props) {
  const {
    alt,
    publicId,
    className = '',
    style,
    transformation,
    serveOriginal,
    htmlOpts = {},
    onClick,
    ...rest
  } = props
  if (serveOriginal) {
    return (
      <img className={className} src={`${CLOUDINARY}/${publicId}`} alt={alt} onClick={onClick} />
    )
  }

  const imgOpts = useCloudinaryImgOptions(rest)

  const [loadingState, setLoadingState] = useState('not-loaded')

  // cloudinary image differentiatees overloaded prop names (ex height, width)
  // that should be passed to the html img tag with the html_ prefix
  const prefixedHtmlOpts = Object.entries(htmlOpts).reduce((prefixed, [k, v]) => {
    prefixed[`html_${k}`] = v
    return prefixed
  }, {})

  return (
    <>
      <Image
        onClick={onClick}
        src={EMPTY_IMAGE}
        className={classnames('cld-img', loadingState, className)}
        responsive
        responsiveUseBreakpoints={false}
        cloudName="aedit"
        alt={alt}
        publicId={getCloudinaryPublicId(publicId)}
        loading="lazy"
        {...imgOpts}
        transformation={transformation || []}
        style={style || {}}
        {...prefixedHtmlOpts}
        onLoad={() => setLoadingState('loaded')}
      />
      <style jsx>{styles}</style>
    </>
  )
}
