/*GENERATED CONFIG DO NOT MANUALLY EDIT*/ export default {
  'arrow-right': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  book: 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'chevron-down': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'chevron-left': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'chevron-right': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'chevron-up': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  clipboard: 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  close: 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'edit-pen': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  hamburger: 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  heart: 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'log-out': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'logo-apple': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'logo-google': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'map-pin': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  'med-cross': 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  person: 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  search: 'nav.d1cfea9abddcdca69dd2ae8ec8eb28d9.svg',
  AEDITION: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  award: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  bookmark: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  camera: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  chat: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  'check-filled-link': 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  'check-filled': 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  compare: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  'download-apple': 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  email: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  globe: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  'help-filled': 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  home: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  minus: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  morph: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  phone: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  plus: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  procedure: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  star: 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  'thumbs-down': 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  'thumbs-up': 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  'user-search': 'other.e7f27b8c3fbff07efd8b6c4dca71a96b.svg',
  calendar: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  clock: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  help: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  mail: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  'microphone-off': 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  microphone: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  pencil: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  provider: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  user: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  'video-off': 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  video: 'provider.dfdbb53bec8c6f7a18655dacbc021f1c.svg',
  facebook: 'social.a6355f42b86bd6f3205a394356abf6e4.svg',
  instagram: 'social.a6355f42b86bd6f3205a394356abf6e4.svg',
  linkedin: 'social.a6355f42b86bd6f3205a394356abf6e4.svg',
  pinterest: 'social.a6355f42b86bd6f3205a394356abf6e4.svg',
  twitter: 'social.a6355f42b86bd6f3205a394356abf6e4.svg',
  amex: 'transactions.74cca2ae449cc72cb908106b9aba833a.svg',
  'credit-card': 'transactions.74cca2ae449cc72cb908106b9aba833a.svg',
  discover: 'transactions.74cca2ae449cc72cb908106b9aba833a.svg',
  jcb: 'transactions.74cca2ae449cc72cb908106b9aba833a.svg',
  mastercard: 'transactions.74cca2ae449cc72cb908106b9aba833a.svg',
  visa: 'transactions.74cca2ae449cc72cb908106b9aba833a.svg',
}
