import AeditLink from 'services/link'
import cn from 'classnames'
import { AeditProBadge } from 'components/badges'

export const AeditLogo = ({ destination, className = '', size = '', pro = false }) => {
  const logo = (
    <h2
      className={cn(
        't-overline flex-nowrap flex items-center justify-center font-normal',
        !size && 'text-xl',
        size === 'medium' && 'text-2xl',
        size === 'large' && 'text-3xl',
        !destination && className
      )}>
      <span className="text-[#939393]">A</span>EDIT
      {pro && <AeditProBadge className="ml-1" />}
    </h2>
  )

  const defaultContainerCn = cn('flex items-center justify-center font-normal', className)

  if (!destination) return logo

  return (
    <AeditLink pathname={destination} className={defaultContainerCn}>
      {logo}
    </AeditLink>
  )
}
