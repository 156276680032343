import React from 'react'
import PropTypes from 'prop-types'
import Head from 'next/head'
import { cloudinaryImage } from 'services/util/cloudinary'

import {
  LINKEDIN_URL,
  FACEBOOK_URL,
  TWITTER_URL,
  INSTAGRAM_URL,
  PINTEREST_URL,
  APP_STORE_URL,
} from 'services/constants'

const SEO = ({
  title,
  description,
  image,
  index,
  follow,
  canonicalPath,
  structuredData = null,
  paywallItemsClassNames = null,
}) => {
  if (
    title.includes(' | The AEDITION') ||
    title.includes(' | AEDIT') ||
    title.includes(' | Careers at AEDIT')
  ) {
    title
  } else if (title) {
    title = title += ' | AEDIT'
  } else {
    title = 'The AEDIT'
  }

  image = image && image.substring(0, 2) === '//' ? 'https:' + image : image

  let canonicalUrl
  if (canonicalPath) {
    canonicalUrl =
      canonicalPath === 'root' ? process.env.WEB_HOST : `${process.env.WEB_HOST}/${canonicalPath}`
  }

  const embedStructuredData = Boolean(structuredData || paywallItemsClassNames?.length)

  const updatedStructuredData = { ...structuredData }
  updatedStructuredData.mainEntityOfPage = canonicalUrl
  if (structuredData?.publisher) {
    updatedStructuredData.publisher = {
      '@type': 'Organization',
      name: 'AEDIT',
      logo: {
        '@type': 'ImageObject',
        name: 'AEDIT Logo',
        url: cloudinaryImage('website/logos/beuro/aedition_logo_beuro.svg', {
          serveOriginal: true,
        }),
      },
    }
  }

  if (paywallItemsClassNames?.length) {
    updatedStructuredData.isAccessibleForFree = 'False'
    updatedStructuredData.hasPart = paywallItemsClassNames.map(paywallItemClassName => ({
      '@type': 'WebPageElement',
      isAccessibleForFree: 'False',
      cssSelector: `.${paywallItemClassName}`,
    }))
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta charSet="UTF-8" key="charset" />

      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@theaedit" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />

      {canonicalUrl && (
        <React.Fragment>
          <link rel="canonical" href={canonicalUrl} />
          <meta property="og:url" content={canonicalUrl} />
        </React.Fragment>
      )}

      <meta property="og:title" content={title} />
      {image && <meta property="og:image" content={image} />}
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Aedit.com | Aesthetic Edit" />
      <meta property="og:type" content="website" />

      {follow && index && <meta name="robots" content={`${follow}, ${index}`} />}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'Organization',
            name: 'AEDIT',
            sameAs: [
              LINKEDIN_URL,
              FACEBOOK_URL,
              TWITTER_URL,
              INSTAGRAM_URL,
              PINTEREST_URL,
              APP_STORE_URL,
            ],
            url: 'https://aedit.com',
            contactPoint: [
              {
                '@type': 'ContactPoint',
                contactType: 'customer support',
                email: 'support@cs.aedit.com',
                url: 'https://aedit.com/contact-us',
              },
            ],
          }),
        }}
      />
      {embedStructuredData && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org',
              ...formatImage(updatedStructuredData),
            }),
          }}
        />
      )}
    </Head>
  )
}

const ASPECT_RATIOS = [1.0 / 1.0, 4.0 / 3.0, 16.0 / 9.0]

function formatImage({ image, ...rest }) {
  const data = { ...rest }
  if (image) {
    data.image = ASPECT_RATIOS.map(aspectRatio =>
      cloudinaryImage(image, { aspectRatio, crop: 'fill' })
    )
  }
  return data
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  canonicalPath: PropTypes.string,
  index: PropTypes.oneOf(['noindex', 'index']),
  follow: PropTypes.oneOf(['nofollow', 'follow']),
  image: PropTypes.string,

  structuredData: PropTypes.shape({
    '@context': PropTypes.string,
    '@type': PropTypes.string,
    author: PropTypes.shape({
      '@type': PropTypes.string,
      name: PropTypes.string,
    }),
    headline: PropTypes.string,
    image: PropTypes.string,
    datePublished: PropTypes.string,
    dateModified: PropTypes.string,
    publisher: PropTypes.bool,
  }),
}

export default SEO
