import Img from '../cloudinary-img'

export default function MarkdownImg({ assetDimensions, ...props }) {
  let ImageWrapper = ({ children }) => children
  const htmlOpts = {}
  if (assetDimensions) {
    const { height, width } = JSON.parse(assetDimensions)
    htmlOpts.height = height
    htmlOpts.width = width
    ImageWrapper = ({ children }) => (
      // the outer div prevents the img from being wider than the original src image,
      // replicating the default img behavior
      <div style={{ maxWidth: width }}>
        {/* inner div uses a relatively positioned div to create a placeholder for the img */}
        <div className="md-img-wrapper" style={{ paddingBottom: `${(height / width) * 100}%` }}>
          {children}
        </div>
      </div>
    )
  }

  return (
    <ImageWrapper>
      <Img htmlOpts={htmlOpts} {...props} />
    </ImageWrapper>
  )
}
