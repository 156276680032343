import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import SPRITESHEET_CONFIG from './GENERATED-CONFIG'
import { IconContext } from './context'
import cn from 'classnames'
import { log } from 'services/util/log'

export const Icon = ({
  name,
  className = '',
  size = '1em',
  width = 24,
  height = 24,
  stroke = 'black',
  strokeWidth = 1.5,
  fill = 'none',
  ...rest
}) => {
  const value = useContext(IconContext)

  const sheet = SPRITESHEET_CONFIG[name]
  useEffect(() => {
    if (value.addSheet) {
      value.addSheet(sheet)
    }
  }, [value])

  if (sheet === undefined) {
    log(`No sheet found for icon named ${name}`, 'warn')
  }
  const href = '#' + name
  const xlink = { xlinkHref: href, href }

  return (
    <svg
      className={cn('icon', `icon-${name}`, className)}
      style={{
        fontSize: size,
      }}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      {...rest}>
      <use {...xlink} />
    </svg>
  )
}

Icon.propTypes = {
  /**
   * Determines which icon you are using
   */
  name: PropTypes.string.isRequired,
  /**
   * For use in styling the Icon
   */
  className: PropTypes.string,
  /**
   * Changes the font-size of the Icon
   */
  size: PropTypes.string,
  /**
   * Width value applied to icon.
   */
  width: PropTypes.number,
  /**
   * Height value applied to icon.
   */
  height: PropTypes.number,
  /**
   * Stroke color of icon.
   */
  stroke: PropTypes.string,
  /**
   * Stroke width of icon.
   */
  strokeWidth: PropTypes.number,
  /**
   * Icon fill color. Could have different effects depending on the icon.
   */
  fill: PropTypes.string,
}
