import { toast } from 'react-toastify'
import { Track } from 'services/analytics'
import { callApi } from 'services/config'
import { Form, Input, Submit } from 'styleguide/packages/form'
import { Toast } from 'styleguide/packages/components'
import styles from './index.scss'
import Grid from 'styleguide/packages/components/grid'
import { log } from 'services/util/log'

const Newsletter = () => {
  const submitForm = async ({ email }, e) => {
    try {
      await callApi('post', '/newsletter', null, { email })

      e.target.reset()
      Track.newsletterSignupSubmitted(email)

      return toast(<Toast.Email message="Thanks for signing up for The Weekly AEDIT." />)
    } catch (error) {
      if (error.message === 'This email address is already subscribed') {
        toast(<Toast.SuccessGold message={`${error.message}.`} />)
      } else {
        log('Failed to subscribe to newsletter', null, { error })
        toast(<Toast.Error message={error.message} />)
      }
    }
  }

  return (
    <section className="o__newsletter">
      <Grid>
        <div className="newsletter-header-wrapper col-span-6">
          <h2 className="BEURO__section-header newsletter-header">
            Want the latest aesthetic and beauty news delivered straight to your inbox?
          </h2>
        </div>
        <Form className="email-form-wrapper col-span-6" onSubmit={submitForm} isGray>
          <Input
            name="email"
            placeholder="Email address"
            type="email"
            label="Sign up for the Weekly AEDIT newsletter"
          />
          <Submit content="Sign&nbsp;up" icon="arrow" viewBox={'0 0 32 32'} />
        </Form>
      </Grid>
      <style jsx>{styles}</style>
    </section>
  )
}
export { Newsletter }
