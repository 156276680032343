import React from 'react'
import PropTypes from 'prop-types'

export default function Grid({ className = '', children, tag = 'div', ...rest }) {
  const Tag = tag
  // @ts-ignore
  return <Tag className={`base-grid-new ${className}`} {...rest}>{children}</Tag>
}

Grid.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.string,
}
