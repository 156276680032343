import React, { useEffect } from 'react'
import { cloudinaryImage } from 'services/util/cloudinary'
import { Track } from 'services/analytics'
import Layout from 'components/layout'
import SEO from 'components/seo'
import FindProvidersSEO from 'components/find_providers_seo'
import { Hero } from './hero'
import { DiscoveryToRecovery } from './discovery_to_recovery'
import { ResearchTopProcedures } from './research_top_procedures'
import { ProviderNetwork } from './provider_network'
import { Aedition } from './aedition'
import { Newsletter } from './newsletter'
import Aeditor from './aeditor'
import PressSection from './press'

import styles from './index.scss'

const Home = props => {
  const { collections, topArticles, articlesByCollection, procedures, providers, press } = props

  useEffect(() => {
    Track.homepageLoaded()

    return () => {}
  }, [])

  return (
    <Layout id="t__home-page-v2" hiddenElements={['container']}>
      <SEO
        title="AEDIT: Your Aesthetic Resource From Discovery To Recovery"
        description="We've done the homework. Your trusted source for aesthetic solutions."
        image={cloudinaryImage('/website/pages/homepage/beuro/home-hero-min.jpg')}
        follow="follow"
        index="index"
        canonicalPath="root"
      />

      <Hero />
      <DiscoveryToRecovery />
      <ResearchTopProcedures procedures={procedures} />
      <ProviderNetwork providers={providers} />
      <Aedition
        collections={collections}
        topArticles={topArticles}
        articlesByCollection={articlesByCollection}
      />
      <Newsletter />
      <Aeditor />
      <PressSection slides={press} />
      <FindProvidersSEO />
      <style jsx>{styles}</style>
    </Layout>
  )
}

export default Home
