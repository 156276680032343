// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
export const isIOS = () => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      window.navigator.platform
    ) ||
    // iPad on iOS 13 detection
    (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}
