import { createContext, useContext, useEffect, useState } from 'react'
import { getLCP, getFID, getCLS, getFCP, getTTFB } from 'web-vitals'
import { Track } from 'services/analytics'
import { v4 } from 'uuid'

const WebVitalsContext = createContext({
  isInitialized: false,
  initialize: () => {},
  reportId: null,
})

export function WebVitalsProvider({ children }) {
  const [isInitialized, setState] = useState(false)
  const initialize = () => setState(true)
  const reportId = v4()
  // TODO: check if useMemo prevents hook re-use
  // const value = useMemo()
  return (
    <WebVitalsContext.Provider value={{ isInitialized, initialize, reportId }}>
      {children}
    </WebVitalsContext.Provider>
  )
}

const getWebVitalsTracker = params => {
  return function trackWebVitals({ name, value }) {
    Track.webVitals({
      ...params,
      metric: name,
      value,
      nonInteraction: 1,
      userAgent: navigator.userAgent,
    })
  }
}

export default function useWebVitals({
  entity_id = null,
  entity_type = 'other',
  slug = null,
  ...rest
} = {}) {
  const { reportId, isInitialized, initialize } = useContext(WebVitalsContext)

  useEffect(() => {
    if (!isInitialized) {
      const trackWebVitals = getWebVitalsTracker({
        reportId,
        entity_id,
        entity_type,
        slug,
        ...rest,
      })
      getLCP(trackWebVitals)
      getFID(trackWebVitals)
      getCLS(trackWebVitals)
      getFCP(trackWebVitals)
      getTTFB(trackWebVitals)

      initialize()
    }
  }, [])
}

// Component version for class components
export function WebVitals(props) {
  useWebVitals(props)
  return null
}
