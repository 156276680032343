import React, { useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { BEURO__Btn } from 'styleguide/packages/components/index.js'

const Submit = props => {
  const {
    name = '',
    content = 'Submit',
    icon,
    iconWidth,
    iconHeight,
    viewBox,
    onClick,
    className = '',
  } = props
  const { formState } = useFormContext() // retrieve all hook methods

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (formState.isSubmitting === true) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 400)
    }
  }, [formState.isSubmitting])

  return (
    <BEURO__Btn.Gold
      className={className}
      name={name}
      content={content}
      icon={icon}
      width={iconWidth}
      height={iconHeight}
      viewBox={viewBox}
      type="submit"
      isPending={isLoading}
      isDisabled={!formState.isDirty}
      onClick={onClick}
    />
  )
}

export default Submit
