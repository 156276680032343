import React from 'react'
import classnames from 'classnames'
import { Chiclet } from 'styleguide/packages/components'

const AeditProBadge = props => {
  const { className } = props

  return (
    <>
      <Chiclet
        className={classnames(
          'bg-black text-white cursor-default max-h-6 rounded-[50px] py-3 font-semibold flex items-center justify-center before:h-0 before:bg-none before:transition-none',
          className
        )}>
        pro
      </Chiclet>
    </>
  )
}

export default AeditProBadge
