import Carousel from 'nuka-carousel'
import useWindowSize from 'hooks/use_window_size'
import AeditLink from 'services/link'
import Img from 'components/img/cloudinary-img'
import { BEURO__Btn } from 'styleguide/packages/components'
import styles from './index.scss'

const moduleContent = [
  {
    image: '/website/pages/homepage/beuro/home-discovery-concerns-min.jpg',
    altText: 'desert dunes',
    title: 'Concerns',
    tag: 'Learn more about the most common aesthetic concerns.',
    button: 'Search by concern',
    redirect_to: '/concerns',
  },
  {
    image: '/website/pages/homepage/beuro/home-discovery-solutions-min.jpg',
    altText: 'two women facing camera',
    title: 'Solutions',
    tag: 'Compare and contrast surgical and non-surgical aesthetic solutions.',
    button: 'Search by solution',
    redirect_to: '/procedures',
  },
  {
    image: '/website/pages/homepage/beuro/home-discovery-procedures-min.jpg',
    altText: 'lips post-cosmetic procedure',
    title: 'Procedures',
    tag: 'Explore our 350+ medically verified guides to cosmetic surgeries and treatments.',
    button: 'Search by procedures',
    redirect_to: '/procedures',
  },
  {
    image: '/website/pages/homepage/beuro/home-discovery-providers-min.jpg',
    altText: 'provider in surgical attire',
    title: 'Providers',
    tag: 'Find a trusted, board certified provider in your area.',
    button: 'Search for providers',
    redirect_to: '/providers',
  },
]

const DiscoveryToRecovery = () => {
  const windowSize = useWindowSize()

  return (
    <section className="o__discovery-to-recovery">
      <h2 className="BEURO__section-header section-header">From Discovery To Recovery</h2>
      {windowSize.width > 768 ? (
        <div className="m__module-card-container">
          {moduleContent.map(card => (
            <AeditLink className="module-card" key={card.title} pathname={card.redirect_to}>
              <Img publicId={card.image} alt={card.altText} />
              <div className="module-card-content">
                <p className="BEURO__overline">{card.title}</p>
                <p className="BEURO__body card-tag">{card.tag}</p>
                <BEURO__Btn.GhostDark content={card.button} />
              </div>
            </AeditLink>
          ))}
        </div>
      ) : (
        <div className="m__module-card-container">
          <Carousel
            pauseOnHover={false}
            speed={1000}
            slidesToScroll={1}
            slidesToShow={windowSize.width / 295} //Prevents main slide from from clashing with before/after slides as window is resized
            cellSpacing={16}
            cellAlign="center"
            enableKeyboardControls
            withoutControls>
            {moduleContent.map(card => (
              <AeditLink className="module-card" key={card.title} pathname={card.redirect_to}>
                <Img publicId={card.image} alt={card.altText} />

                <div className="module-card-content">
                  <p className="BEURO__overline">{card.title}</p>
                  <p className="BEURO__body card-tag">{card.tag}</p>
                  <BEURO__Btn.GhostDark content={card.button} />
                </div>
              </AeditLink>
            ))}
          </Carousel>
        </div>
      )}
      <style jsx>{styles}</style>
    </section>
  )
}
export { DiscoveryToRecovery }
