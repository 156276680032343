import React from 'react'
import classnames from 'classnames'
import { toggleOpen } from 'styleguide/packages/util'
import { Chiclet, BEURO__Icon } from 'styleguide/packages/components'

import styles from './index.scss'

const EvidenceBasedBadge = props => {
  const { className } = props

  const toggleModal = () => toggleOpen('evidence-based-modal')

  return (
    <>
      <Chiclet className={classnames('evidence-based-badge', className)} onClick={toggleModal}>
        Evidence Based
        <BEURO__Icon
          name="checkmark"
          className="checkmark-icon"
          width="1rem"
          height="1rem"
          color="#ffffff"
          viewBox="0 -3 32 32"
        />
      </Chiclet>
      <style jsx>{styles}</style>
    </>
  )
}

export default EvidenceBasedBadge
