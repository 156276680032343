import React from 'react'

const BaseP = props => {
  const { className = '', children, ...rest } = props

  return (
    <p className={className} {...rest}>
      {children}
    </p>
  )
}

export { BaseP }
