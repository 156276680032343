import Link from 'next/link'

const AeditLink = props => {
  let {
    pathname = '',
    params = {},
    as = '',
    isShallow = false,
    rel = 'noopener noreferrer',
    target = '',
    className = '',
    children,
    href,
    ...rest
  } = props

  return (
    <Link href={href || { pathname, query: params }} as={as} shallow={isShallow}>
      <a className={`text-inherit aedit-link ${className}`} rel={rel} target={target} {...rest}>
        {children}
      </a>
    </Link>
  )
}

export default AeditLink
