import React from 'react'
import { classes } from 'styleguide/packages/util/index.js'

const Chiclet = props => {
  const { className = '', isRound = false, onClick, children, ...rest } = props

  const classNames = classes([
    className,
    'base-chiclet',
    {
      '--round': isRound,
      '--clickable': onClick,
    },
  ])

  return (
    <span className={classNames} onClick={onClick} {...rest}>
      {children}
    </span>
  )
}

Chiclet.Dark = props => <Chiclet {...props} className={`${props.className || ''} --dark`} />
Chiclet.Light = props => <Chiclet {...props} className={`${props.className || ''} --light`} />
Chiclet.Gold = props => <Chiclet {...props} className={`${props.className || ''} --gold`} />
Chiclet.White = props => <Chiclet {...props} className={`${props.className || ''} --white`} />
Chiclet.Black = props => <Chiclet {...props} className={`${props.className || ''} --black`} />

export default Chiclet
