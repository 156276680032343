import { Image } from 'cloudinary-react'
import { Fragment } from 'react'
import cn from 'classnames'
import styles from './img.scss'
import useCloudinaryImgOptions from './use-cloudinary-img-options'
import { cloudinaryImage } from 'services/util/cloudinary'
import Preload from 'components/preload'

// Need to separate the query objects for images that render at different relative widths
// across the 760 breakpoint
const DESKTOP_QUERIES = {
  '(min-width: 1351px)': 1900,
  '(max-width: 1350px) and (min-width: 769px)': 1350,
}
const MOBILE_QUERIES = {
  '(max-width: 768px) and (min-width: 601px)': 768,
  '(max-width: 600px) and (min-width: 411px)': 600,
  '(max-width: 410px) and (min-width: 381px)': 410,
  '(max-width: 380px)': 380,
}
/**
 *
 * @param {Object} props
 * @param {String} props.alt  Alt text for the image
 * @param {String} props.publicId Cloudinary ID for the image
 * @param {Number} props.relativeWidth Approximate fraction of screen img takes up (0 - 1.0)
 * @param {Number} props.desktopWidth Allows you to specify relative width for desktop breakpoints
 * @param {Number} props.desktopWidth Allows you to specify relative width for desktop breakpoints
 * @param {Number} props.mobileWidth Allows you to specify relative width for mobile breakpoints
 * @param {Number} props.width Allows you to hard-code a pixel width
 * @param {Object} props.desktopOptions Allows you to pass in cloudinary options to apply only to desktop breakpoints
 * @param {Object} props.mobileOptions Allows you to pass in cloudinary options to apply only to mobile breakpoints
 */
export default function PreloadedImg(props) {
  const {
    alt,
    publicId,
    className = '',
    relativeWidth,
    desktopWidth = 1.0,
    mobileWidth = 1.0,
    desktopOptions = {},
    mobileOptions = {},
    width,
    ...rest
  } = props

  const imgOpts = useCloudinaryImgOptions(rest)

  const getMobileSrc = screenWidth =>
    cloudinaryImage(publicId, {
      ...imgOpts,
      dpr: 2.0, // can't auto calc DRP on preload, assume at least 2.0 device
      width: width || screenWidth * (relativeWidth || mobileWidth),
      ...mobileOptions,
    })

  const getDesktopSrc = screenWidth =>
    cloudinaryImage(publicId, {
      ...imgOpts,
      dpr: 2.0, // can't auto calc DRP on preload, assume at least 2.0 device
      width: width || screenWidth * (relativeWidth || desktopWidth),
      ...desktopOptions,
    })

  const srcs = Object.entries(DESKTOP_QUERIES).map(([media, screenWidth]) => ({
    src: getDesktopSrc(screenWidth),
    media: media,
  }))
  Object.entries(MOBILE_QUERIES).forEach(([media, screenWidth]) =>
    srcs.push({
      src: getMobileSrc(screenWidth),
      media: media,
    })
  )

  return (
    <>
      <picture>
        {srcs.map(({ src, media }) => (
          <Fragment key={media}>
            <Preload as="image" href={src} media={media} />
            <source srcSet={src} media={media} />
          </Fragment>
        ))}
        <Image className={cn('cld-preload-img', className)} alt={alt} />
      </picture>
      <style jsx>{styles}</style>
    </>
  )
}
