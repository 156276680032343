import Carousel from 'nuka-carousel'
import Img from 'components/img/cloudinary-img'
import AeditLink from 'services/link'
import SlideIndicators from '../_components/slide-indicators/slideIndicators'
import { BEURO__Btn, BEURO__Icon } from 'styleguide/packages/components'
import styles from './index.scss'

const ProviderNetwork = ({ providers }) => {
  return (
    <section className="o__provider-network">
      <div className="m__provider-network-cta">
        <h2 className="BEURO__section-header">AEDIT Provider Network</h2>
        <h3>
          Connect with our elite community of trusted and qualified board certified providers.
        </h3>
        <AeditLink pathname={`/providers`} data-cy={`providers-link`}>
          <BEURO__Btn.Gold content="Find a provider" />
        </AeditLink>
      </div>

      <div className="m__provider-network-carousel">
        <Carousel
          className="carousel-provider"
          pauseOnHover={false}
          renderCenterLeftControls={() => {}}
          renderCenterRightControls={() => {}}
          slidesToShow={1}
          cellSpacing={16}
          renderBottomCenterControls={SlideIndicators}
          speed={2200}
          autoplayInterval={6000}
          autoplay
          wrapAround
          enableKeyboardControls>
          {providers.map(provider => (
            <AeditLink
              pathname={provider.profile_url}
              className="provider-slide-container p-3 md:p-6"
              key={provider.display_name}>
              <div className="provider-slide-container-header">
                <div className="provider-image">
                  <Img
                    publicId={provider.image}
                    alt={provider.display_name}
                    transformation={[
                      { aspectRatio: 1, crop: 'thumb', gravity: 'north' },
                      { radius: 'max' },
                    ]}
                  />
                </div>
                <div className="slide-header-content">
                  <h2 className="BEURO__subsection-header">{provider.display_name}</h2>
                  <h3 className="BEURO__overline">{provider.specialty_name}</h3>
                </div>
              </div>
              <ul>
                <li className="provider-info-item-wrapper">
                  <BEURO__Icon name="expertise" height="32" width="32" viewBox="0 0 24 24" />

                  <p className="BEURO__body BEURO__body--small provider-">
                    {provider.primary_title}
                  </p>
                </li>
                {provider.secondary_title && (
                  <li className="provider-info-item-wrapper">
                    <BEURO__Icon name="expertise" height="32" width="32" viewBox="0 0 24 24" />
                    <p className="BEURO__body BEURO__body--small provider-title">
                      {provider.secondary_title}
                    </p>
                  </li>
                )}
                <li className="provider-info-item-wrapper">
                  <BEURO__Icon name="award" height="32" width="32" viewBox="0 0 24 24" />
                  <p className="BEURO__body BEURO__body--small provider-title">
                    {provider.certification_status}
                  </p>
                </li>
                <li className="provider-info-item-wrapper">
                  <BEURO__Icon name="pin" height="32" width="32" viewBox="0 0 24 24" />
                  <p className="BEURO__body BEURO__body--small provider-title">
                    {provider.location}
                  </p>
                </li>
              </ul>
            </AeditLink>
          ))}
        </Carousel>
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}
export { ProviderNetwork }
