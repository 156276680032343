import dayjs from 'dayjs'
import { filterValid } from './abstract'
import { capitalize } from 'services/util/format'
import cloudinary from 'cloudinary-core'
import { getCloudinaryPublicId } from 'services/util/cloudinary'
import { CLOUDINARY_CORE_INIT_OPTIONS } from 'services/constants'

const cl = new cloudinary.Cloudinary({ ...CLOUDINARY_CORE_INIT_OPTIONS, quality: 'auto:best' })

export const getCardHeroUrl = relativeUrl =>
  cl.url(getCloudinaryPublicId(relativeUrl), {
    crop: 'crop',
    aspect_ratio: '9:2',
    gravity: 'faces',
  })

// for the many properties on providers with this shape
export const display = item => item?.display_name

export const label = item => item?.label

export const filterSpecialties = filterValid(provider => provider.specialties || [])(
  specialty => specialty.id !== 'none' && specialty.id !== 'other'
)

export const fullLocation = provider =>
  Array.from([
    provider?.primary_practice?.location?.city,
    provider?.primary_practice?.location?.state_abbreviation,
  ])
    .filter(Boolean)
    .join(', ')

export const fullName = provider => [provider.first_name, provider.last_name].join(' ')
fullName.withMiddleInitial = provider =>
  [
    provider.first_name,
    provider?.middle_initial ? provider.middle_initial + '.' : '',
    provider.last_name,
  ].join(' ')

export const fullTitle = provider =>
  Array.from([fullName(provider), provider.professional_title_short])
    .filter(Boolean)
    .join(', ')

fullTitle.withMiddleInitial = provider =>
  Array.from([fullName.withMiddleInitial(provider), provider.professional_title_short])
    .filter(Boolean)
    .join(', ')

export const graduateSchoolAndYear = provider => {
  if (!provider?.education_graduation_institution?.name) {
    return ''
  }
  if (!provider?.graduate_graduation_year) {
    return provider.education_graduation_institution.name
  }
  return `${provider.education_graduation_institution.name} (${provider.graduate_graduation_year})`
}

export const primaryPracticeTimezone = provider => provider?.primary_practice?.location?.timezone

export const professionalTitle = provider =>
  provider?.salutation && provider.salutation !== 'none'
    ? Array.from([provider.salutation, provider.last_name])
        .filter(Boolean)
        .map(capitalize)
        .join('. ')
    : shortTitle(provider)

export const profileFlag = (provider, slug) =>
  (provider.flags || []).filter(f => f.is_active).find(f => f.slug === slug)?.value

export const residency = provider => {
  if (!provider?.education_residency) {
    return
  }
  const endDate = Boolean(provider?.residency_date_end)
    ? dayjs(new Date(provider.residency_date_end)).format('MMM YYYY')
    : undefined

  return [display(provider?.types?.[0] ?? {}), provider?.education_residency, endDate]
    .filter(Boolean)
    .join(', ')
}

export const salutation = provider =>
  (provider?.salutation && provider.salutation !== 'none'
    ? capitalize(provider.salutation) + '. '
    : '') + `${provider.first_name} ${provider.last_name}`

salutation.withMiddleInitial = provider =>
  (provider?.salutation && provider.salutation !== 'none'
    ? capitalize(provider.salutation) + '. '
    : '') + fullName.withMiddleInitial(provider)

salutation.lastNameOnly = provider => {
  if (!provider) {
    return ''
  }

  return (
    (provider?.salutation && provider.salutation !== 'none'
      ? `${capitalize(provider.salutation)}. `
      : '') + provider.last_name
  )
}

export const shortTitle = provider =>
  `${provider.first_name} ${provider.last_name}${
    provider.professional_title_short ? ', ' + provider.professional_title_short : ''
  }`

export const undergradSchoolAndYear = provider => {
  if (!provider?.education_undergrad_institution?.name) {
    return ''
  }
  if (!provider?.undergrad_graduation_year) {
    return provider.education_undergrad_institution.name
  }
  return `${provider.education_undergrad_institution.name} (${provider.undergrad_graduation_year})`
}

export const yearsOfExperience = provider => {
  const thisYear = new Date().getFullYear()
  const startYear = provider?.years_of_experience || thisYear
  const delta = thisYear - startYear
  if (delta <= 1) {
    return ''
  }
  if (delta < 5) {
    return 'Less than five years of experience'
  }
  return `${delta} years of experience`
}

export const isPubliclyAccessible = provider => {
  return (
    provider &&
    provider.id &&
    provider.practices?.length &&
    Number(provider.is_active) &&
    Number(provider.publicly_viewable)
  )
}
