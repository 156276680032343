import Img from 'components/img/cloudinary-img'
import { Chiclet } from 'styleguide/packages/components'
import cn from 'classnames'

export const AeditProLogo = ({ className = '' }) => {
  return (
    <span className={cn('aedit-pro-logo d-flex', className)}>
      <Img
        publicId="website/logos/aedit_logo_light.svg"
        className="mr-1"
        alt="Aedit logo"
        serveOriginal
      />
      <Chiclet.Black isRound>Pro</Chiclet.Black>
    </span>
  )
}
