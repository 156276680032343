import { useState, useEffect } from 'react'
import AeditLink from 'services/link'
import Img from 'components/img/cloudinary-img'
import { BEURO__Btn } from 'styleguide/packages/components'
import { classes } from 'styleguide/packages/util'
import styles from './index.scss'

const Aedition = ({ topArticles, articlesByCollection, collections }) => {
  const [activeCollectionId, setActiveCollectionId] = useState('0')
  const [activeArticles, setActiveArticles] = useState(topArticles)

  useEffect(() => {
    if (activeCollectionId === '0') {
      setActiveArticles(topArticles)
    } else {
      const filteredArticles = articlesByCollection.filter(
        articles => articles[0].collection_id === activeCollectionId
      )[0]

      setActiveArticles(filteredArticles)
    }
  }, [activeCollectionId])

  return (
    <section className="o__aedition-module">
      <div className="header-background" />

      <div className="content-container">
        <AeditLink pathname={`/aedition`}>
          <Img
            serveOriginal
            publicId="website/logos/beuro/aedition_logo_beuro.svg"
            alt="aedition logo"
            className="aedition-logo"
          />
        </AeditLink>
        <h2 className="aedition-subheader">
          On-the-pulse editorial content in support of <br /> every step of your aesthetic journey.
        </h2>

        <div className="collection-menu">
          {collections.map((collection, idx) => (
            <div className="collection-menu-item-wrapper" key={`collection-${idx}`}>
              <p
                key={collection.id}
                className={classes([
                  'BEURO__breadcrumbs',
                  'collection-menu-item',
                  activeCollectionId === collection.id && 'collection-menu-item--active',
                ])}
                onClick={() => setActiveCollectionId(collection.id)}>
                {collection.title}
              </p>
              <div
                className={classes([
                  'collection-menu-rail',
                  activeCollectionId === collection.id && 'collection-menu-rail--active',
                ])}
              />
            </div>
          ))}
        </div>

        <div className="articles-list">
          {activeArticles.map(article => (
            <div className="article" key={article.id}>
              <AeditLink pathname={`/aedition/${article.slug}`}>
                <p className="BEURO__chiclet collection-chiclet">{article.collection.title}</p>
                <div className="image-container">
                  <Img
                    crop="fill"
                    height={12.5 /*rem*/ * 16 /*px*/}
                    publicId={article.articleImage.src}
                    alt={article.articleImage.description || article.title}
                  />
                </div>
                <h2 className="BEURO__subsection-header mb-1 mt-3">{article.title}</h2>
                <p className="BEURO__body">{article.intro}</p>
              </AeditLink>
            </div>
          ))}
        </div>
      </div>

      <AeditLink pathname={`/aedition`} className="button-container">
        <BEURO__Btn.Gold content="Discover more articles" />
      </AeditLink>

      <style jsx>{styles}</style>
    </section>
  )
}
export { Aedition }
