import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/global'
import { FlagSmithStateContext } from 'services/flagsmith/state'
import AeditLink from 'services/link'
import styles from './index.scss'
import { alphabeticalSort } from 'services/util/abstract'

// Can remove this if seo_location_flag flag is removed
const FindProvidersSEOWrapper = props => {
  const { flags } = useContext(FlagSmithStateContext)

  return flags.seo_location_flag.enabled ? <FindProvidersSEO {...props} /> : <></>
}

const FindProvidersSEO = props => {
  const { className = '', ...rest } = props
  const { findAProvider } = useContext(GlobalContext)

  const [active, setActive] = useState(() => 0)

  const data = [
    { list: alphabeticalSort(findAProvider.locations, 'show'), name: 'Locations' },
    { list: alphabeticalSort(findAProvider.specialties, 'show'), name: 'Specialties' },
    { list: alphabeticalSort(findAProvider.procedures, 'show'), name: 'Procedures' },
  ]

  return (
    <section className="find-providers-seo px-6 lg:px-12 xl:px-28 bg-white border-0 border-solid border-t-2 border-ivory">
      <div
        className={`content-wrapper col-span-full py-12 md:pt-24 md:pb-26 ${className}`}
        {...rest}>
        <h2 className="BEURO__section-header mb-2">Find Top Aesthetic Providers Near You</h2>
        <section
          className={[
            'button-strip hidden md:flex h-13 w-full mb-4',
            'border-0 border-solid border-b-2 border-ivory-hover',
          ].join(' ')}>
          {['Locations', 'Specialties', 'Procedures'].map((text, idx) => (
            <button
              key={text}
              type="button"
              className={`BEURO__overline mb-1 ${
                active === idx
                  ? 'current -mb-[2px] mb-[-2px] border-0 border-solid border-b-2 border-gold'
                  : ''
              }`}
              onClick={() => setActive(idx)}>
              {text}
            </button>
          ))}
        </section>

        {data.map(({ list, name }, idx) => (
          <section
            className={`data-list col-span-full ${active === idx ? 'current' : ''}`}
            key={name}>
            <h3 className="BEURO__body BEURO__body--bold mb-2 mt-4">Providers by {name}</h3>
            <ul className="list-of-links grid p-0 gap-x-6 grid-rows-[auto_1fr] md:grid-flow-col md:grid-rows-[repeat(5,_1fr)] lg:grid-rows-[repeat(4,_1fr)]">
              {list.map(item => (
                <li
                  key={item.show}
                  className="BEURO__body col-span-3 underline text-ellipsis whitespace-nowrap overflow-x-hidden">
                  <AeditLink pathname={item.route} target="_blank">
                    {item.show}
                  </AeditLink>
                </li>
              ))}
            </ul>
          </section>
        ))}
      </div>

      <style jsx>{styles}</style>
    </section>
  )
}

FindProvidersSEOWrapper.propTypes = {
  className: PropTypes.string,
}

export default FindProvidersSEOWrapper
