import React, { useState, useEffect } from 'react'
import Img from 'components/img/preloaded-img'
import { isIOS } from 'services'
import cn from 'classnames'

import styles from './index.scss'

const QR_CODE_PUBLIC_ID =
  'production/website/text_me_app/b2d54c35-d046-40c6-8b64-c41471bd9aef_short_url_qr_code.png'

const AppQRCode = ({ className, large = false, small = false }) => {
  const [isIOSDevice, _isIOSDevice] = useState(false)

  // Should consider setting the device info at the app layer
  useEffect(() => {
    isIOS() ? _isIOSDevice(true) : null
  }, [])

  return (
    <>
      {isIOSDevice ? (
        <></>
      ) : (
        <Img
          publicId={QR_CODE_PUBLIC_ID}
          alt="App QR Code"
          className={cn('qr-code-image', large && 'size-lg', small && 'size-sm', className)}
        />
      )}
      <style jsx>{styles}</style>
    </>
  )
}

export default AppQRCode
