import React from 'react'
import { BaseP } from './index'

const BEURO__P = props => <BaseP {...props} className={`${props.className}`} />

BEURO__P.Overline = props => (
  <BEURO__P {...props} className={`${props.className || ''} BEURO__overline`} />
)
BEURO__P.Body = props => <BEURO__P {...props} className={`${props.className || ''} BEURO__body`} />
BEURO__P.BodyBold = props => (
  <BEURO__P {...props} className={`${props.className || ''} BEURO__body BEURO__body--bold`} />
)
BEURO__P.BodySmall = props => (
  <BEURO__P {...props} className={`${props.className || ''} BEURO__body BEURO__body--small`} />
)
BEURO__P.OverlineSmall = props => (
  <BEURO__P
    {...props}
    className={`${props.className || ''} BEURO__overline BEURO__overline--small`}
  />
)
BEURO__P.Breadcrumbs = props => (
  <BEURO__P {...props} className={`${props.className || ''} BEURO__breadcrumbs`} />
)
BEURO__P.Caption = props => (
  <BEURO__P {...props} className={`${props.className || ''} BEURO__caption`} />
)
BEURO__P.CaptionMono = props => (
  <BEURO__P {...props} className={`${props.className || ''} BEURO__caption BEURO__caption--mono`} />
)
BEURO__P.Chiclet = props => (
  <BEURO__P {...props} className={`${props.className || ''} BEURO__chiclet`} />
)

export default BEURO__P
