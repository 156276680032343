import React, { useState, useEffect, useContext } from 'react'
import { Track } from 'services/analytics'
import AeditLink from 'services/link'
import { FlagSmithStateContext } from 'services/flagsmith/state'
import { BEURO__Btn } from 'styleguide/packages/components'
import { isIOS } from 'services'
import { AeditLogo } from 'components/logos'
import Img from 'components/img/cloudinary-img'
import {
  TextAppLinkForm,
  AppStoreButton,
  AppQRCode,
} from 'components/aeditor_download_module/components'

import styles from './index.scss'

// Can remove this if app_module flag is removed
const AeditorWrapper = props => {
  const { flags } = useContext(FlagSmithStateContext)

  return flags.app_module.enabled ? <Aeditor {...props} /> : <></>
}

const Aeditor = () => {
  const [isIOSDevice, _isIOSDevice] = useState(false)

  // Should consider setting the device info at the app layer
  useEffect(() => {
    isIOS() ? _isIOSDevice(true) : null
  }, [])

  return (
    <section className="o__aeditor">
      <div className="m__image-wrapper">
        <Img
          publicId="/website/pages/homepage/beuro/home-aeditor-model-with-line.png"
          className="m__image"
          alt="Model"
        />
      </div>

      <div className="m__content-wrapper">
        <AeditLogo destination="/aeditor-app" size="large" />

        <h2 className="BEURO__section-header section-tagline">Visualize Your Ideal Aesthetic</h2>
        <p className="BEURO__body">
          Ever wonder what you'd look like after a cosmetic procedure? Our 3D Aesthetic Simulator
          allows you to ‘try on’ cosmetic procedures and instantly visualize possible results right
          from the palm of your hand — anytime, anywhere.
        </p>

        <TextAppLinkForm entityType="home-page" />

        <div className="button-wrapper">
          {isIOSDevice ? (
            <AppStoreButton />
          ) : (
            <AeditLink pathname="/aeditor-app">
              <BEURO__Btn.Ghost content="Try it now" onClick={() => Track.textMeTheAppClicked()} />
            </AeditLink>
          )}

          <AppQRCode />
        </div>
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}
export default AeditorWrapper
