import { toast } from 'react-toastify'
import { Track } from 'services/analytics'
import { Form, Input, Submit } from 'styleguide/packages/form'
import { Toast } from 'styleguide/packages/components'

const TextAppLinkForm = props => {
  const { entityType = null } = props
  const defaultValues = { telephone: '' }
  const branchOptions = {
    channel: '',
    tags: [],
    feature: 'TextMeTheApp',
    data: {
      $og_image_url:
        'https://res.cloudinary.com/aedit/image/upload/q_auto/website/pages/app-download/app_hero_image.png',
    },
  }

  const branchErrorHandler = error => {
    if (error) {
      Track.textMeTheAppFailed(error.message)
      return toast(<Toast.Error message="Sorry, there was an error. Please try again." />)
    } else {
      return toast(<Toast.Text message="Thanks! Check your messages for a text from AEDIT." />)
    }
  }

  const sendSMS = data => {
    branch.sendSMS(data.telephone, branchOptions, {}, branchErrorHandler)
  }

  return (
    <Form
      isGray
      className="form-wrapper mt-4 flex items-end flex-wrap md:flex-nowrap"
      onSubmit={sendSMS}
      isResetOnSubmit
      defaultValues={defaultValues}>
      <Input
        label="Text to download AEDIT for iOS"
        name="telephone"
        type="telephone"
        placeholder="Phone number"
      />
      <Submit
        name="Send"
        content="Send"
        icon="arrow"
        onClick={() => Track.textMeTheAppClicked(entityType)}
      />
    </Form>
  )
}
export default TextAppLinkForm
