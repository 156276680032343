import React from 'react'
import { TryOn3DModal } from 'components/modals'
import classnames from 'classnames'
import { Track } from 'services/analytics'
import { Chiclet, BEURO__Icon } from 'styleguide/packages/components'
import { showPortal } from 'components/portal'

import styles from './index.scss'

const TryOn3DModalBadge = props => {
  const { className, procedureId, procedureName } = props

  const toggleModal = () => {
    const analytics = {
      id: procedureId,
      procedureName,
    }

    Track.tryOn3dBadgeClicked(analytics)

    showPortal(TryOn3DModal, { procedureName })
  }

  return (
    <>
      <Chiclet className={classnames('try-on-3d-modal-badge', className)} onClick={toggleModal}>
        3d try-on
        <BEURO__Icon
          name="chevron-right"
          className="chevron-right-icon"
          width="0.8rem"
          height="0.8rem"
        />
      </Chiclet>
      <style jsx>{styles}</style>
    </>
  )
}

export default TryOn3DModalBadge
