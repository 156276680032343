const ServerCompiler = process.browser ? null : require('markdown-to-jsx').compiler

const BLOCK_ELEMENTS = ['ul', 'ol', 'blockquote', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']

/**
 * @typedef {ASTNode[]} AST Abstract Syntax Tree
 */

/**
 * @typedef {String|ASTComponentNode} ASTNode AST Node
 */

/**
 * @typedef {Object} ASTComponentNode React component node in the AST
 * @property {String} component Name of the component which will be used to render the node
 *  This can be either a regular HTML component such as `h1` or a name of your custom component.
 *  In the latter case you need to supply custom component mapping to the component MarkdownJsx.
 * @property {?Object|undefined} props Props passed to the component
 * @property {?ASTNode[]|undefined} children Component children
 */

/**
 * @typedef {Object.<String, {component: ?Function=, props: ?Object=}>} Components Custom components
 *  mapping
 *
 * This corresponds to the object you would normally pass to options.overrides in markdown-to-jsx.
 * @see https://github.com/probablyup/markdown-to-jsx#optionsoverrides---override-any-html-tags-representation
 */

/**
 * Parses raw markdown string into an AST.
 *
 * You can then pass the AST into the component `MarkdownJsx` in order to quickly render the whole
 * content.
 *
 * @param {String} content Markdown string
 * @param compiler Compiler
 * @return {AST|Promise<AST>} Parsed AST (JSON-serializable, so you can return it from getStaticProps, etc.)
 */
export const parseMarkdown = (content, compiler = null) => {
  // markdown-to-jsx's default behavior is to build a component tree by invoking React.createElement
  // on each node of the syntax tree.
  //
  // Our own createElement function decouples the parsing phase from the rendering phase by
  // building a JSON-serializable abstract syntax tree (AST).
  //
  // The resulting AST looks something like this:
  //
  // [
  //   {
  //     component: 'a',
  //     props: { href: '/' },
  //     children: [
  //       {
  //         component: 'span',
  //         children: ['Hello world!']
  //       }
  //     ]
  //   }
  // ]

  if (!process.browser) {
    compiler = ServerCompiler
  } else if (!compiler) {
    return import('markdown-to-jsx').then(({ compiler }) => {
      return parseMarkdown(content, compiler)
    })
  }

  if (typeof content === 'undefined' || content === null) {
    return null
  }

  if (typeof content !== 'string') {
    throw new Error(`Cannot parse markdown content of type ${typeof content}`)
  }

  let inline = true

  /**
   * @param {String} component
   * @param {?Object|undefined} props
   * @param {?ASTNode[]|undefined} children
   * @return {ASTComponentNode}
   */
  const createElement = (component, props, ...children) => {
    const node = { component, props, children }

    inline = inline && !BLOCK_ELEMENTS.includes(component)

    // get rid of all undefined props (not JSON-serializable)
    if (!props) {
      delete node.props
    } else {
      for (const [key, val] of Object.entries(props)) {
        if (typeof val === 'undefined') {
          delete props[key]
        }
      }
    }

    return node
  }

  // ensure that the AST is an array and not a React component wrapped in a div or span
  const wrapper = null

  const ast = compiler(content, {
    createElement,
    wrapper,
  })

  // wrap the result
  switch (ast.length) {
    case 0:
      return null

    case 1:
      if (typeof ast[0] === 'string') {
        return [
          {
            component: 'span',
            props: { key: 'outer' },
            children: ast,
          },
        ]
      } else {
        return ast
      }

    default:
      const Wrapper = inline ? 'span' : 'div'
      return [
        {
          component: Wrapper,
          props: { key: 'outer' },
          children: ast,
        },
      ]
  }
}
