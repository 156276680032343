import { useMemo } from 'react'

const CLOUDINARY_IMAGE_OPTIONS = [
  'format',
  'quality',
  'width',
  'height',
  'gravity',
  'crop',
  'zoom',
  'dpr',
  'hasWatermark',
  'serveOriginal',
  'aspectRatio',
  'background',
]

export default function useCloudinaryImgOptions(props) {
  return useMemo(() => {
    const imgProps = {
      fetchFormat: 'auto',
      width: 'auto',
      dpr: 'auto',
      crop: 'limit',
      quality: 'auto',
      ...props,
    }
    const cloudinaryImageOptions = {}
    for (const key of CLOUDINARY_IMAGE_OPTIONS) {
      if (imgProps[key] != undefined) {
        cloudinaryImageOptions[key] = imgProps[key]
        delete imgProps[key]
      }
    }

    return cloudinaryImageOptions
  }, [...Object.values(props)])
}
