import { callApi } from 'services/config'
import { contentful } from 'services/contentful'
import Home from 'features/home/index'
import useWebVitals from 'hooks/use_web_vitals'

const HomePage = props => {
  useWebVitals()
  return <Home {...props} />
}

export const getServerSideProps = async () => {
  let collections = [{ title: 'All', id: '0' }]
  let providers
  let procedures

  //Fetch Initial Data
  const [topArticlesRes, collectionsRes, { press }, providersRes, procedureRes] = await Promise.all(
    [
      contentful.getArticles({
        'fields.isPinnedHomepage': true,
        limit: 3,
      }),
      contentful.getCollections({
        select: 'sys.id,sys.contentType.sys.id,fields.title',
      }),
      contentful.getPress(),
      callApi('get', '/config'),
      callApi('get', '/analytics/entity/views', {
        entity_type: 'procedure',
        _limit: 5,
      }),
    ]
  )
  //Fetch Article and Procedure Image Data
  const fetchArticles = collectionsRes.collections.map(collection => {
    return contentful
      .getArticles({
        'fields.isPinnedHomepage': true,
        'fields.collection.sys.id': collection.id,
        limit: 3,
      })
      .then(res => (collection.articles = res.articles))
  })

  const fetchProcedureImages = procedureRes.map(async procedure => {
    return await callApi('get', `/procedure/${procedure.analytics.entity_id}`).then(res => {
      procedure.analytics.image = res.primary_image_thumbnail_relative_url
      return procedure.analytics
    })
  })

  const articlesByCollectionRes = await Promise.all(fetchArticles)
  const procedureImagesRes = await Promise.all(fetchProcedureImages)

  //Set Homepage Data
  const { articles: topArticles } = topArticlesRes

  const articlesByCollection = articlesByCollectionRes.map(collection =>
    collection.map(article => {
      article.collection_id = article.collection.id
      return article
    })
  )

  collectionsRes.collections.forEach(({ title, id }) => collections.push({ title, id }))

  providers = providersRes.provider.HOMEPAGE_PROVIDERS.providers

  procedures = procedureImagesRes.sort((a, b) => {
    return a.value < b.value ? -1 : a.value > b.value ? 1 : 0
  })

  return {
    props: {
      collections,
      topArticles,
      articlesByCollection,
      providers,
      procedures,
      press,
    },
  }
}

export default HomePage
