import Carousel from 'nuka-carousel'
import { redirectTo } from 'services/next'
import SlideIndicators from '../_components/slide-indicators/slideIndicators'
import { BEURO__Btn } from 'styleguide/packages/components'
import styles from './index.scss'

const heroContent = [
  {
    header: 'Your Aesthetic Resource From Discovery To Recovery',
    tag: "We've done the homework: AEDIT simplifies the search for aesthetic solutions and trusted providers.",
    button: 'Find a procedure',
    redirect_to: '/procedures',
  },
  {
    header: 'Own Your Aesthetic Journey',
    tag: 'Find answers to your aesthetic questions in a matter of clicks with our SmartSolutions Finder.',
    button: 'Search by concern',
    redirect_to: '/concerns',
  },
  {
    header: 'Educate. Empower. Connect.',
    tag: 'Connect with trusted, board certified providers that meet our strict standards and your personal needs.',
    button: 'Find a provider',
    redirect_to: '/providers',
  },
  {
    header: 'Visualize Your Ideal Aesthetic',
    tag: "'Try on' cosmetic procedures and instantly visualize results with The AEDITOR, our 3D Aesthetic Simulator.",
    button: 'Try The AEDITOR',
    redirect_to: '/aeditor-app',
  },
  {
    header: 'Content with Credentials',
    tag: 'Read the latest aesthetic, beauty, and wellness news on The AEDITION',
    button: 'View all articles',
    redirect_to: '/aedition',
  },
]

const Heading = ({ children, h1 = false }) => {
  const H = h1 ? 'h1' : 'h2'
  return <H className="BEURO__hero">{children}</H>
}

const Hero = () => {
  return (
    <section className="o__hero">
      <div className="m__hero-background">
        <div className="hero-content-wrapper">
          <Carousel
            className="carousel-hero"
            pauseOnHover={false}
            renderCenterLeftControls={() => {}}
            renderCenterRightControls={() => {}}
            renderBottomCenterControls={SlideIndicators}
            speed={1500}
            autoplayInterval={3500}
            dragging
            autoplay
            wrapAround
            enableKeyboardControls>
            {heroContent.map((slide, idx) => (
              <div className="slide-wrapper" key={slide.header}>
                <div className="slide-content">
                  <Heading h1={idx === 0}>{slide.header}</Heading>
                  <p className="BEURO__body hero-tag">{slide.tag}</p>
                  <BEURO__Btn.Gold
                    content={slide.button}
                    onClick={() => redirectTo(slide.redirect_to)}
                  />
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <style jsx>{styles}</style>
    </section>
  )
}
export { Hero }
