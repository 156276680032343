import React, { useEffect, useState } from 'react'
import { parseMarkdown } from './parser'

/**
 * Renders markdown content.
 *
 * If you provide raw markdown string then this component parses the string, otherwise it skips
 * parsing and quickly renders the parsed content.
 *
 * This component is meant to have the same public API as the component MarkdownJsx from
 * markdown-to-jsx and it should produce the same results.
 *
 * @param {String|AST} props.children Either a markdown string or an already parsed AST
 * @param {?{overrides: ?Components=, wrapper: ?Function=}=} props.options markdown-to-jsx options
 * @param {Object} props Remaining props are passed to the outermost element
 */
export const MarkdownJsx = props => {
  const { children: content, options = null, ...rest } = props
  const { overrides: components = null, wrapper = null } = options ?? {}
  const Wrapper = wrapper ?? React.Fragment

  const ast = useMarkdownParser(content)
  const rendered = renderNode(ast, components, rest)

  return <Wrapper>{rendered}</Wrapper>
}

/**
 * @param {String|AST} content
 * @return {AST|undefined}
 */
const useMarkdownParser = content => {
  // avoid reparsing the whole content if we are about to rerender the component

  let initialAst = content ?? undefined
  if (typeof content === 'string') {
    if (process.browser) {
      initialAst = undefined // parse the content in the useEffect below
    } else {
      initialAst = parseMarkdown(content)
    }
  }

  const [ast, setAst] = useState(initialAst)

  useEffect(() => {
    if (typeof content === 'string') {
      parseMarkdown(content).then(setAst)
    } else {
      setAst(ast)
    }
  }, [content])

  return ast
}

/**
 * Renders individual AST node into JSX.
 *
 * @param {String|ASTNode[]|ASTNode} node AST Node
 * @param {?Components=} components Custom components mapping
 * @param {?Object=} immediateProps Props passed to all nodes on the current level
 * @return {String|Array|JSX.Element|undefined} Rendered content
 */
const renderNode = (node, components = null, immediateProps = null) => {
  if (typeof node === 'string') {
    return node
  } else if (Array.isArray(node)) {
    return node
      .map(child => renderNode(child, components, immediateProps))
      .filter(child => typeof child !== 'undefined')
  } else if (node?.component) {
    const { component, props, children } = node

    const mapping = components?.[component]
    const renderedComponent = mapping?.component ?? component
    const renderedProps = { ...props, ...mapping?.props, ...immediateProps }
    const renderedChildren = renderNode(children, components)

    return React.createElement(renderedComponent, renderedProps, ...renderedChildren)
  } else {
    return undefined
  }
}
