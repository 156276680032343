import React from 'react'
import { Track } from 'services/analytics'
import { APP_STORE_URL } from 'services/constants'
import AeditLink from 'services/link'
import { Icon } from 'components/icon/Icon'
import cn from 'classnames'

const AppStoreButton = ({ className = '', onClick }) => {
  return (
    <AeditLink
      className={cn('cursor-pointer', className)}
      pathname="/aeditor-app"
      href={APP_STORE_URL}
      target="_blank"
      onClick={() => {
        Track.appDownloadClicked()
        onClick?.()
      }}>
      <Icon name="download-apple" width={135} height={40} stroke="" fill="" />
    </AeditLink>
  )
}
export default AppStoreButton
