import styles from './index.scss'

const SlideIndicators = ({
  currentSlide,
  slideCount,
  goToSlide,
  activeSlideColor = '#fff',
  inactiveSlideColor = 'rgba(255,255,255,0.5)',
}) => {
  return (
    <div className="slide-indicator-list">
      {[...Array(slideCount)].map((sc, slide) => (
        <span key={slide} onClick={() => goToSlide(slide)} className="pointer">
          <div
            className="slide-indicator"
            style={{
              backgroundColor: currentSlide === slide ? activeSlideColor : inactiveSlideColor,
            }}
          />
        </span>
      ))}
      <style jsx>{styles}</style>
    </div>
  )
}

export default SlideIndicators
