import React, { useEffect, useState } from 'react'
import { Container } from 'components/grid'
import PropTypes from 'prop-types'
import Footer from './footer'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import { getLocationFromIp } from 'services/location'
import { AdSenseSlot } from 'components/adsense'
import { ADSENSE_AD_SLOTS } from 'services/constants'

toast.configure({
  autoClose: 4000,
  draggable: false,
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_LEFT,
  className: `custom-toastify`,
  bodyClassName: 'custom-toastify-body',
  closeButton: false,
  closeOnClick: true,
})

const Layout = props => {
  const {
    children,
    hiddenElements,
    className,
    id,
    hideFooterAd = false,
    footerAdClassName = 'bg-white',
  } = props
  const [location, setLocation] = useState({})

  const getUserLocation = async () => {
    const userLocale = await getLocationFromIp()
    if (userLocale) {
      setLocation(userLocale)
    }
  }

  useEffect(() => {
    getUserLocation()
  }, [location.city, location.state])

  return (
    <>
      <div
        id={id}
        className={classnames('main-content nav', {
          [className]: className,
        })}>
        {hiddenElements.includes('container') ? children : <Container>{children}</Container>}
      </div>

      {!hideFooterAd && (
        <div className={classnames('py-6', footerAdClassName)}>
          <AdSenseSlot slot={ADSENSE_AD_SLOTS.GLOBAL_ABOVE_FOOTER} />
        </div>
      )}

      {!hiddenElements.includes('footer') && <Footer />}
    </>
  )
}

Layout.propTypes = {
  hiddenElements: PropTypes.arrayOf(PropTypes.string), // can be: container, footer
}

Layout.defaultProps = {
  hiddenElements: [],
}

export default Layout
