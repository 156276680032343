import AeditLink from 'services/link'
import Img from 'components/img/cloudinary-img'
import { BEURO__Btn } from 'styleguide/packages/components'
import styles from './index.scss'

const ResearchTopProcedures = ({ procedures = [] }) => {
  return (
    <section className="o__research-top-procedures">
      <h2 className="BEURO__subsection-header">Research Top Procedures</h2>
      <div className="m__module-card-container">
        {procedures.map(procedure => (
          <AeditLink
            className="module-card"
            key={procedure.entity_id}
            pathname={`/procedure/${procedure.slug}`}
            data-cy={`${procedure.slug}-link`}>
            <div className="procedure-image">
              <Img
                publicId={procedure.image}
                alt={procedure.value}
                transformation={[
                  { aspectRatio: 1, crop: 'thumb', gravity: 'center' },
                  { radius: 'max' },
                ]}
              />
            </div>
            <p className="BEURO__body BEURO__body--small procedure-name">{procedure.value}</p>
          </AeditLink>
        ))}
      </div>
      <AeditLink pathname={`/procedures`} data-cy={`procedures-link`}>
        <BEURO__Btn.Gold content="View all procedures" />
      </AeditLink>
      <style jsx>{styles}</style>
    </section>
  )
}
export { ResearchTopProcedures }
